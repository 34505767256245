<template>
    <div class="page">
        <Tables ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading"
            :searchFormData="searchFormData" :showRefresh="false" :showSearch="true" @getList="getList"
            @clearSearch="clearSearch">

            <!--搜索自定义内容-->
            <el-form-item slot="search-item">
                <el-input v-model="searchFormData.car_no" size="small" clearable placeholder="车牌"></el-input>
            </el-form-item>
            <el-form-item slot="search-item">
                <el-input v-model="searchFormData.sn_no" size="small" clearable placeholder="相机编号"></el-input>
            </el-form-item>
            <el-form-item slot="search-item">
                <el-date-picker class="from-item-width" size="small" unlink-panels v-model="searchFormData.in_time" type="date"
                    value-format="yyyy-MM-dd" placeholder="驶入时间">
                </el-date-picker>
            </el-form-item>
            <el-form-item slot="search-item">
                <el-select v-model="searchFormData.alarm_status" placeholder="状态" clearable size="small">
                    <el-option label="占位" :value="1">
                    </el-option>
                    <el-option label="正常" :value="2">
                    </el-option>
                </el-select>
            </el-form-item>

            <!--表格内容-->
            <vxe-table-column slot="table-item" field="sn_no" title="相机编号" align="center" min-width="100px" />
            <vxe-table-column slot="table-item" field="park_num" title="车位号" align="center" min-width="100px" />
            <vxe-table-column slot="table-item" field="device" title="对应枪编号" align="center" min-width="100px" />
            <vxe-table-column slot="table-item" field="car_no" title="车牌" align="center" min-width="100px" />
            <vxe-table-column slot="table-item" field="in_time" title="驶入时间" align="center" min-width="100px" />
            <vxe-table-column slot="table-item" field="out_time" title="驶离时间" align="center" min-width="100px" />
            <vxe-table-column slot="table-item" field="alarm_status" title="状态" align="center" min-width="100px" >
                <template v-slot="{ row }">
                    <span>{{types.alarm_status[row.alarm_status]||'未知类型'}}</span>
                </template>
            </vxe-table-column>
            <vxe-table-column slot="table-item" field="min" title="停放时长(分钟)" align="center" min-width="100px" />
        </Tables>

    </div>
</template>
  
<script>
import Tables from '@/components/tables'
export default {
    name: 'market-plan-list',
    components: {
        Tables
    },
    data() {
        return {
            // 表格信息
            tableName: '历史记录',
            isLoading: false,
            tableData: [],
            totalPage: 0,
            sn_no:0,
            searchFormData: {},
            types: {
                alarm_status: {
                    1: "横跨车位",
                    2: "油车占位",
                    3: "电车占位",
                    4: "空闲"
                },
            }
        }
    },
    computed: {
        editDisabled() {
            for (let item of this.editNeedData) {
                if (this.editDialogFormData[item] != this.editDialogFormOriData[item]) {
                    return false
                }
            }
            return true
        }
    },
    mounted() {
        this.init()
    },
    activated() {
        this.init()
    },
    methods: {
        // 初始化请求
        init() {
            if (this.$route.query.id) {
                if (this.sn_no != this.$route.query.id) {
                    this.sn_no = this.$route.query.id;
                    this.getList();
                }
            } else {
                this.$confirm('当前页面数据失效，请返回项目列表重新打开', '提示', {
                    cancelButtonClass: 'btn-custom-cancel',
                    confirmButtonText: '确定',
                    showCancelButton: false,
                    showClose: false,
                    closeOnClickModal: false,
                    type: 'warning'
                }).then(() => {
                    this.closeTab()
                })
            }
        },
        // 表格列表
        async getList(currentPage, pageSize) {
            const params = {
                token: this.$store.state.user.token,
                page: currentPage,
                size: pageSize,
                sn_no: this.sn_no
            }
            this.isLoading = true
            const searchData = this.searchFormData
            const res = await this.$api.Rf.GetParkList(params, searchData);
            this.tableData = res.data
            this.totalPage = res.total
            this.isLoading = false
        },
        // 重置搜索
        clearSearch() {
            this.searchFormData = {}
        },
        // 重置form
        resetFormData() {
            this.addDialogFormData = {
                plan_type: 10
            }
            this.editDialogFormData = {}
            this.editDialogFormOriData = {}
            if (this.$refs.addForm) {
                this.$refs.addForm.resetFields()
            }
            if (this.$refs.editForm) {
                this.$refs.editForm.resetFields()
            }
        },
    }
}
</script>
  
<style lang="scss" scoped></style>
  